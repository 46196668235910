<template>
  <b-tabs class="page-tabs" pills>
    <b-tab>
      <template #title>
        <span>
          Fatture
        </span>
      </template>
      <div class="tab-pane__content">
        <plafonds-usage :company-id="companyId" />
        <b-card body-class="pt-0">
          <template #header>
            <card-header
              title="Elenco fatture"
            />
          </template>

          <div class="d-flex bg-gray-100 mx-n3 p-2">
            <new-invoice-upload
              :entity-id="companyId"
              entity-class="INVOICE"
              documentEntityType="INVOICE"
              :on-success="onSuccessAdd"
            />
          </div>
          <div class="mt-3">
            <user-invoice-history-table :key="listKey" :completeData="hasScoreData"/>
          </div>
        </b-card>
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <span>
          Accordati
        </span>
      </template>
      <div class="tab-pane__content">
        <b-card>
          <template #header>
            <card-header
              title="Elenco accordati"
            />
          </template>

          <user-plafond-table :key="listKey" :completeData="hasScoreData"/>
        </b-card>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import moment from 'moment/moment';

const UserInvoiceHistoryTable = () => import('@/components/userInvoice/UserInvoiceHistoryTable.vue');
const NewInvoiceUpload = () => import('@/components/userInvoice/NewInvoiceUpload.vue');
const UserPlafondTable = () => import('@/components/userPlafond/UserPlafondTable.vue');
const PlafondsUsage = () => import('@/components/userPlafond/PlafondsUsage.vue');
const CardHeader = () => import('@/components/cardHeader/CardHeader.vue');

export default {
  name: 'CrInvoiceTabs',
  components: {
    PlafondsUsage,
    NewInvoiceUpload,
    UserInvoiceHistoryTable,
    UserPlafondTable,
    CardHeader,
  },
  data() {
    return {
      hasLoaded: false,
      listKey: moment().format(),
    };
  },
  computed: {
    hasScoreData() {
      if (this.hasLoaded !== this.$store.getters['score/hasCurrentScore']) {
        this.changePreloaderStatus(this.$store.getters['score/hasCurrentScore']);
      }
      return this.$store.getters['score/hasCurrentScore'];
    },
    companyId() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return currentProfile?.companyId;
    },
  },
  methods: {
    changePreloaderStatus(state) {
      this.hasLoaded = state;
      // console.debug('CrInvoiceTabs setpreloader', !state);
      this.$store.dispatch('setPreloader', !state);
    },
    onSuccessAdd() {
      this.listKey = moment().format();
    },
  },
};
</script>

<style scoped>

</style>
